import * as React from 'react'
import LinkTile from './linkTile'
import * as Styles from '../styles/layout.module.scss'

const LinkList = ({ links, 
                        title,
                        locale }) => {
  return links?(
    
    <div class={Styles.list}>
      <h3>{title}</h3>
                 {links.map(link => (
            <LinkTile to={link.url}
                      locale={locale} 
                      text={link.text}
                      subtext={link.subtext}/> 
          ))
        }      

</div>

  ):(<div></div>)
} 

export default LinkList